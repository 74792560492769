/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'add': {
    width: 10,
    height: 11,
    viewBox: '0 0 10 11',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 .071a1 1 0 00-1 1v3H1a1 1 0 100 2h3v3a1 1 0 002 0v-3h3a1 1 0 000-2H6v-3a1 1 0 00-1-1z" _fill="#fff"/>'
  }
})
